.hero {
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero header {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--navbar-height);
  box-sizing: border-box;
  padding: 5rem 7rem;
}

@media screen and (max-width: 650px) {
  .hero header {
    padding: 2.5rem 4rem;
  }
}

.hero header::before {
  z-index: -1;
  content: "";
  position: absolute;
  background-image: url('../assets/electric_circuit.svg');
  background-size: cover;
  border-radius: 4rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.15;
}

.hero .links {
  display: flex;
  gap: 1rem;
}