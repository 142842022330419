:root {
  --main-bg-color: rgb(249, 245, 245);
  --navbar-height: 5rem;
  min-width: 400px;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--main-bg-color);
  width: 100%;
}

.MuiLink-root:hover {
  color: rgb(38, 73, 161)
}