footer {
  margin-top: 2rem;
  padding: 3rem 0px;
}

footer h6 {
  display: flex;
  justify-content: center;
}

footer .heart:hover {
  color: red;
}