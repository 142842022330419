.navbar {
  position: fixed;
  width: 100%;
  min-width: 400px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--main-bg-color);
  height: var(--navbar-height);
  z-index: 1;
  /* padding-right: 2rem; */
}

.navbar .link {
  margin-right: 1.75rem;
  text-decoration: none;
  font-weight: 500;
}

.navbar.contrasted {
  box-shadow: 0px 0px 5px rgb(211, 209, 209);
}

.link.up-arrow {
  position: relative;
  top: 0.1rem;
  /* font-size: 2rem; */
  /* width: 100px; */
}

.arrow-icon {
  width: 3rem;
}

.link.up-arrow.return {
  color: rgb(205, 199, 199);
}