/* .about-me {
  min-height: calc(100vh - var(--navbar-height));
} */

.about-me .paper {
  max-width: 40%;
}

@media (max-aspect-ratio: 5/4) {
  .about-me .paper {
    max-width: 75%;
  }
}